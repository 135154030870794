<template>
  <b-modal
    id="modal-preview-deposit-template"
    ref="refModal"
    :title="t('Hợp đồng cọc')"
    :ok-title="t('Đóng')"
    ok-only
    size="lg"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    scrollable

    @show="fetchPdfPreviewUrl"
    @hidden="resetModal"
  >
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner

        class="text-center"
        variant="secondary"
      />
    </div>
    <div v-else>

      <b-button
        class="mb-1"
        variant="primary"
        @click="downloadTemplate"
      >
        {{ t('Tải mẫu') }}
      </b-button>
      <iframe
        v-if="pdfUrl && !isLoading"
        :src="pdfUrl"
        style="width: 100%; height: 80vh; border: none;"
      />
    </div>

  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
  BButton,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useDepositTemplatePreviewModal from './useDepositTemplatePreviewModal';

export default {
  components: {
    BModal,
    BSpinner,
    BButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      fetchPdfPreviewUrl,
      pdfUrl,
      resetItemLocal,
      resetModal,
      isLoading,
      downloadTemplate,
      t,
    } = useDepositTemplatePreviewModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      fetchPdfPreviewUrl,
      pdfUrl,
      // Form Validation
      resetForm,
      clearForm,
      isLoading,
      refFormObserver,
      getValidationState,
      downloadTemplate,
      t,
    };
  },
};
</script>
