import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDepositTemplates(ctx, params) {
      return useJwt.getDepositTemplates(params).then(response => response);
    },
    createDepositTemplate(ctx, data) {
      return useJwt.createDepositTemplate(data).then(response => response);
    },
    updateDepositTemplate(ctx, data) {
      return useJwt.updateDepositTemplate(data).then(response => response);
    },
    previewDepositTemplate(ctx, id) {
      return useJwt.previewDepositTemplate(id).then(response => response);
    },
    deleteDepositTemplates(ctx, data) {
      return useJwt.deleteDepositTemplates(data).then(response => response);
    },
  },
};
